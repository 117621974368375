import { EmailRegex, NameRegex, PhoneRegex } from "@utils/Constant"
import { matchTagRegex } from "@utils/Helpers"
import * as Yup from "yup"

export const dealerDetailsValidationSchema = Yup.object().shape({
  ownerIsApplicant: Yup.boolean(),
  manufactureNow: Yup.boolean(),
  isMultiPlate: Yup.boolean(),
  ownershipType: Yup.string(),  
  ownerDetails: Yup.object().when("isMultiPlate", {
    is: true,
    then: Yup.object(),
    otherwise: Yup.object().when("ownershipType", {
      is: "Company",
      then: Yup.object({        
        email: Yup.string()
          .max(100, "100 characters maximum")
          .matches(EmailRegex, "Must enter a valid email address")
          .required("Email is required"),
        phone: Yup.string()
          .max(50, "50 characters maximum")
          .matches(PhoneRegex, "Invalid phone number")
          .required("Phone is required"),
      }),
      otherwise: Yup.object({
        firstName: Yup.string()
          .max(50, "50 characters maximum")
          .matches(NameRegex, "Enter valid characters")
          .required("First Name is required"),
        lastName: Yup.string()
          .max(50, "50 characters maximum")
          .matches(NameRegex, "Enter valid characters")
          .required("Last Name is required"),
        email: Yup.string()
          .max(100, "100 characters maximum")
          .matches(EmailRegex, "Must enter a valid email address")
          .required("Email is required"),
        phone: Yup.string()
          .max(50, "50 characters maximum")
          .matches(PhoneRegex, "Invalid phone number")
          .required("Phone is required"),
      })     
    }),
  }),
  companyDetails: Yup.object().when("ownershipType", {
    is: "Company",
    then: Yup.object({
      companyNumber: Yup.string()
            .matches(/^[0-9]+$/, "Can only contain numbers")
            .max(50, "50 characters maximum")
            .required("Company Number is required"),
      companyOwner: Yup.string()
          .required("Company Name is required")
          .test("companyOwner", "Enter valid characters", (value) => !matchTagRegex(value))
          .max(255, "255 characters maximum"),
    }),
    otherwise: Yup.object(),
  }),  
  ownerAddress: Yup.object().when("isMultiPlate", {
    is: true,
    then: Yup.object(),
    otherwise: Yup.object().when("manufactureNow", {
      is: true,
      then: Yup.object({
        building: Yup.string()
          .test("building", "Enter valid characters", (value) => !matchTagRegex(value))
          .max(50, "50 characters maximum"),
        unit: Yup.string()
          .test("unit", "Enter valid characters", (value) => !matchTagRegex(value))
          .max(50, "50 characters maximum"),
        street: Yup.string()
          .test("street", "Enter valid characters", (value) => !matchTagRegex(value))
          .required("Address is required")
          .max(50, "50 characters maximum"),
        suburb: Yup.string()
          .test("suburb", "Enter valid characters", (value) => !matchTagRegex(value))
          .max(50, "50 characters maximum"),
        city: Yup.string()
          .test("city", "Enter valid characters", (value) => !matchTagRegex(value))
          .required("City is required")
          .max(50, "50 characters maximum"),
        postCode: Yup.string()
          .required("Postcode is required")
          .max(4, "4 characters maximum")
          .matches(/^[0-9]+$/, "Must only contain numbers"),
      }),
    }),
  }),

  vehicleDetails: Yup.object().when("manufactureNow", {
    is: true,
    then: Yup.object().when("ownershipType", {
      is:"Individual",
      then: Yup.object({
        driverLicense: Yup.string()
          .matches(/^[a-zA-Z0-9]+$/, "Enter valid characters")
          .max(50, "50 characters maximum"),
        dob: Yup.date()
          .nullable()
          .required("Date of birth is required"),
        vehicleMake: Yup.string()
          .required("Make is required")
          .test("vehicleMake", "Enter valid characters", (value) => !matchTagRegex(value))
          .max(20, "20 characters maximum"),
        vehicleModel: Yup.string()
          .required("Model is required")
          .test("vehicleModel", "Enter valid characters", (value) => !matchTagRegex(value))
          .max(20, "20 characters maximum"),
      }),
      otherwise: Yup.object().when("ownershipType",{
        is:"Company",
        then: Yup.object({          
          vehicleMake: Yup.string()
            .test("vehicleMake", "Enter valid characters", (value) => !matchTagRegex(value))
            .required("Make is required")
            .max(20, "20 characters maximum"),
          vehicleModel: Yup.string()
            .test("vehicleModel", "Enter valid characters", (value) => !matchTagRegex(value))
            .required("Model is required")
            .max(20, "20 characters maximum"),
        }),
      }), 
    }),
    otherwise: Yup.object(),
  })
    
})
